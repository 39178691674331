html, body
  padding: 0
  margin: 0
  font-family: 'Montserrat', sans-serif
  max-width: 420px
  margin: auto

a
  color: inherit
  text-decoration: none

p
  margin: 0
  padding: 0

*
  box-sizing: border-box
