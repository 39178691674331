@import 'vars'
  
footer.footer
  padding: $layout-padding
  margin: 8px 0
  color: #C4C4C4
  text-align: center
  font:
    weight: bold
    size: 24px
