@import 'vars'

header.header
  padding: $layout-padding
  margin-top: 8px

.back_line
  display: flex
  align-items: center

  &__fake
    width: 0
    height: 24px

  &__link
    color: #000000
    font-size: 20px
    cursor: pointer

    &__arrow
      margin-right: 6px

.title
  color: #000000
  font:
    weight: bold
    size: 30px
  margin: 0 0 20px 0
